import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setLoginInfo, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }, 
  // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Orientation Calls Here
  // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ORIENTATIONPOST: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Orientation Calls Here
  // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  orientationPOST: () => Promise.resolve(),
  
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get('/api/account/my-account');
          const { user } = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: "SOMETHING"
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: "SOMETHING"
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post('/api/account/login', {
      email,
      password
    });
    const { accessToken, user } = response.data;
    setSession(accessToken);
    setLoginInfo(user.orientationcomplete, user.lastlogin, user.firstname, user.startedchallenge)

    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const register = async (email, password, firstName, lastName, phoneNumber, birthday, gender, zipCode) => {
    const response = await axios.post('/api/account/register', JSON.stringify({
      email,
      password,
      firstName,
      lastName,
      phoneNumber,
      birthday,
      gender,
      zipCode
    }), {headers: {
      'Content-Type' : 'application/json'
    }});
    const { accessToken, user } = response.data;

    setSession(accessToken);
    setLoginInfo(user.orientationcomplete, user.lastlogin, user.firstname, user.startedchallenge)

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Orientation Calls Here
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // aspectArray = Object of Submit Content
  // endpoint = What Link the API Call Makes (Health)
  // FutureSelf = api/FutureSelf
  // DailyHabits = api/DailyHabit
  // WEALTH = api/Wealth/(Goal) ? Goal = Worship/Education/Affluence/...
  // localStorageName = name of localStorage variable (HealthGoals)

  const orientationPOST = async (aspectArray, endpoint ) => {
    const body = JSON.stringify({
      aspectArray
    })

    let cleanBody = body.replace('{"aspectArray":', ''); 
    cleanBody = cleanBody.slice(0, cleanBody.length-1); 

    const response = await axios.post(`/api/${endpoint}`, cleanBody, 
    {headers: {
      'Content-Type' : 'application/json'
    }});
    const { user } = response.data;

    dispatch({
      type: 'ORIENTATIONPOST',
      payload: {
        user
      }
    });
  };

  const resetPassword = async (email) => {

    await axios.get(`/api/Account/reset-password-request?email=${email}`, 
    {headers: {
      'Content-Type' : 'application/json'
    }});
  };

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Orientation Calls Here
        // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        orientationPOST,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

