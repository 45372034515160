import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import OWealthInstruction from 'src/pages/orientation/O_WealthInstruction';
// components
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guards/AuthGuard';
import DashboardLayout from '../layouts/dashboard';
// layouts
import OrientationLayout from '../layouts/orientation';


// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
              <Login />
          ),
        },
        {
          path: 'register',
          element: (
              <Register />
          ),
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'recover', element: <Recover /> },
      ],
    },

    {
      path: 'orientation',
      element:(
        <AuthGuard>
          <OrientationLayout/>
        </AuthGuard>
      ),
      children: [
        { path: 'vision', element: <OVision /> },
        { path: 'tutorial', element: <OTutorial /> },
        { path: 'wealthIntro', element: <OWealthInstruction /> },
        { path: 'dailyHabits', element: <ODailyHabits /> },
        // { path: 'monthlyGoals', element: <OMonthlyGoals /> },
        // { path: 'weeklyGoals', element: <OWeeklyGoals /> },
        { path: 'worshipGoals', element: <OWorshipGoals /> },
        { path: 'educationGoals', element: <OEducationGoals /> },
        { path: 'affluenceGoals', element: <OAffluenceGoals /> },
        { path: 'lifestyleGoals', element: <OLifestyleGoals /> },
        { path: 'teamGoals', element: <OTeamGoals /> },
        { path: 'healthGoals', element: <OHealthGoals /> },
        // { path: 'futureSelf', element: <OFutureSelf /> },
      ],
    },
    

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const Recover = Loadable(lazy(() => import('../pages/auth/Recover')));
// Orientation
const OVision = Loadable(lazy(() => import('../pages/orientation/O_Vision')));
const OTutorial = Loadable(lazy(() => import('../pages/orientation/O_Tutorial')));
const ODailyHabits = Loadable(lazy(() => import('../pages/orientation/O_DailyHabits')));
const OWorshipGoals = Loadable(lazy(() => import('../pages/orientation/O_Goals_1_Worship')));
const OEducationGoals = Loadable(lazy(() => import('../pages/orientation/O_Goals_2_Education')));
const OAffluenceGoals = Loadable(lazy(() => import('../pages/orientation/O_Goals_3_Affluence')));
const OLifestyleGoals = Loadable(lazy(() => import('../pages/orientation/O_Goals_4_Lifestyle')));
const OTeamGoals = Loadable(lazy(() => import('../pages/orientation/O_Goals_5_Team')));
const OHealthGoals = Loadable(lazy(() => import('../pages/orientation/O_Goals_6_Health')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
// Main
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));