import { AppBar, Box, MenuItem, Stack, Toolbar } from '@mui/material';
// @mui
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { React, useEffect, useState } from 'react';
import moment from 'moment';
// hooks
import Marquee from "react-fast-marquee";
import { useNavigate } from 'react-router-dom';
// config
import {
  DASHBOARD_HEADER_DESKTOP, DASHBOARD_HEADER_MOBILE
} from '../../../config';
// hooks
import useAuth from '../../../hooks/useAuth';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import axios from '../../../utils/axios';
// utils
import cssStyles from '../../../utils/cssStyles';
// ----------------------------------------------------------------------



const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse',
    })(({ theme }) => ({
      boxShadow: 'none',
      ...cssStyles(theme).bgBlur(),
      transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.shorter,
      }),
      [theme.breakpoints.up('lg')]: {
        // width: `calc(100% - ${DASHBOARD_NAVBAR_WIDTH + 1}px)`,
        // ...(isCollapse && {
        //   width: `calc(100% - ${DASHBOARD_NAVBAR_COLLAPSE_WIDTH}px)`,
        // }),
      },
    }));

  const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: DASHBOARD_HEADER_MOBILE,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 0),
      minHeight: DASHBOARD_HEADER_DESKTOP,
    },
  }));

  DashboardHeader.propTypes = {
    onOpenSidebar: PropTypes.func,
  };

  //#region DashboardHeader Function
  export default function DashboardHeader({ onOpenSidebar }) {

    //#region Const Initialization
    const navigate = useNavigate();
    const { logout } = useAuth();
    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();
    const { isCollapse } = useCollapseDrawer();

    //#endregion


  const handleLogout = async () => {
    try {
      await logout?.();
      if (isMountedRef.current) {
        navigate('/');
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };
  //#endregion
    //#region Initial API Calls
  const [WeeklyGoals, setWeeklyGoals] = useState([{ID: null, GOAL: '', GOAL_NUM: ''}, {ID: null, GOAL: '', GOAL_NUM: ''}, {ID: null, GOAL: '', GOAL_NUM: ''}]);
    useEffect(() => {
      const formattedDate = moment(new Date()).format().toString().split('T')[0]
      axios.get(`/api/Goal/Weekly?date=${formattedDate}`, 
      {headers: {
        'Content-Type' : 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`
      }})
      .then((response) => {
        if ([...response.data].length > 0) {
          setWeeklyGoals([...response.data]);
        }
      })
    }, []);


    const [WorshipGoals, setWorshipGoals] = useState([{ID: null, GOAL_TITLE: '', GOAL_TYPE: '', GOAL: '', GOAL_NUM: ''}, {ID: null, GOAL_TITLE: '', GOAL_TYPE: '', GOAL: '', GOAL_NUM: ''}, {ID: null, GOAL_TITLE: '', GOAL_TYPE: '', GOAL: '', GOAL_NUM: ''}]);
    useEffect(() => {
      axios.get('/api/Wealth/Worship',  
      {headers: {
        'Content-Type' : 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`
      }})
      .then((response) => {
        if ([...response.data].length > 0) {
          setWorshipGoals([...response.data]);
        }
      })
    }, []);
    const [EducationGoals, setEducationGoals] = useState([{ID: null, GOAL_TITLE: '', GOAL_TYPE: '', GOAL: '', GOAL_NUM: ''}, {ID: null, GOAL_TITLE: '', GOAL_TYPE: '', GOAL: '', GOAL_NUM: ''}, {ID: null, GOAL_TITLE: '', GOAL_TYPE: '', GOAL: '', GOAL_NUM: ''}]);
    useEffect(() => {
      axios.get('/api/Wealth/Education',  
      {headers: {
        'Content-Type' : 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`
      }})
      .then((response) => {
        if ([...response.data].length > 0) {
          setEducationGoals([...response.data]);
        }
      })
    }, []);
    const [AffluenceGoals, setAffluenceGoals] = useState([{ID: null, GOAL_TITLE: '', GOAL_TYPE: '', GOAL: '', GOAL_NUM: ''}, {ID: null, GOAL_TITLE: '', GOAL_TYPE: '', GOAL: '', GOAL_NUM: ''}, {ID: null, GOAL_TITLE: '', GOAL_TYPE: '', GOAL: '', GOAL_NUM: ''}]);
    useEffect(() => {
      axios.get('/api/Wealth/Affluence',  
      {headers: {
        'Content-Type' : 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`
      }})
      .then((response) => {
        if ([...response.data].length > 0) {
          setAffluenceGoals([...response.data]);
        }
      })
    }, []);
    const [LifestyleGoals, setLifestyleGoals] = useState([{ID: null, GOAL_TITLE: '', GOAL_TYPE: '', GOAL: '', GOAL_NUM: ''}, {ID: null, GOAL_TITLE: '', GOAL_TYPE: '', GOAL: '', GOAL_NUM: ''}, {ID: null, GOAL_TITLE: '', GOAL_TYPE: '', GOAL: '', GOAL_NUM: ''}]);
    useEffect(() => {
      axios.get('/api/Wealth/Lifestyle',  
      {headers: {
        'Content-Type' : 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`
      }})
      .then((response) => {
        if ([...response.data].length > 0) {
          setLifestyleGoals([...response.data]);
        }
      })
    }, []);
    const [TeamGoals, setTeamGoals] = useState([{ID: null, GOAL_TITLE: '', GOAL_TYPE: '', GOAL: '', GOAL_NUM: ''}, {ID: null, GOAL_TITLE: '', GOAL_TYPE: '', GOAL: '', GOAL_NUM: ''}, {ID: null, GOAL_TITLE: '', GOAL_TYPE: '', GOAL: '', GOAL_NUM: ''}]);
    useEffect(() => {
      axios.get('/api/Wealth/Team',  
      {headers: {
        'Content-Type' : 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`
      }})
      .then((response) => {
        if ([...response.data].length > 0) {
          setTeamGoals([...response.data]);
        }
      })
    }, []);
    const [HealthGoals, setHealthGoals] = useState([{ID: null, GOAL_TITLE: '', GOAL_TYPE: '', GOAL: '', GOAL_NUM: ''}, {ID: null, GOAL_TITLE: '', GOAL_TYPE: '', GOAL: '', GOAL_NUM: ''}, {ID: null, GOAL_TITLE: '', GOAL_TYPE: '', GOAL: '', GOAL_NUM: ''}]);
    useEffect(() => {
      axios.get('/api/Wealth/Health',  
      {headers: {
        'Content-Type' : 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`
      }})
      .then((response) => {
        if ([...response.data].length > 0) {
          setHealthGoals([...response.data]);
        }
      })
    }, []);
    //#endregion
    //#region Ticker
    //eslint-disable-next-line
    const ticker = function(){
      document.querySelector("#ticker").css({left:"120%"}).animate({ "left": "-420px" }, 1000, 'linear', ticker);

    //#endregion
  }
  //#endregion

  return (
    <RootStyle isCollapse={isCollapse}>
      <ToolbarStyle>
      <a href='https://www.wealthyway.com/' target={'_blank'} rel='noreferrer'> <img alt={"Logo"} style={{display: 'flex', justifyContent: 'left', width: '150px', position: 'relative', left: '10px', zIndex: 1}} src="../../logo/Logo.png"/></a><br/><br/>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1, zIndex: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <LanguagePopover />
          <NotificationsPopover /> */}
          {/* <ContactsPopover /> */}

          <MenuItem onClick={handleLogout} sx={{ typography: 'body2', py: 1, px: 2, borderRadius: 1, m: 1, display: 'flex', justifyContent: 'right', right: '20px' }}>
            Logout
          </MenuItem>
        </Stack>
      </ToolbarStyle>
      <hr style={{color: 'grey', height: '1', width: '100%', background: 'grey'}}/>

      {/* Pulling from: https://npm.io/package/react-fast-marquee */}
      <Marquee 
        gradient={false} 
        pauseOnHover
        style={{background: 'none', backgroundImage: 'none', }}
      >
        <span style={{paddingRight: '25px'}}><span style={{color: '#d6ad5c', fontWeight: 'bold', fontSize: '18px'}}>Weekly Goals</span><span>:</span></span> 
        <span>
          <span style={{paddingRight: '35px'}}><span style={{color: '#d6ad5c', fontSize: '18px'}}>&#10162; </span><span>{WeeklyGoals[0].GOAL}</span></span> 
          <span style={{paddingRight: '35px'}}><span style={{color: '#d6ad5c', fontSize: '18px'}}>&#10162; </span><span>{WeeklyGoals[1].GOAL}</span></span> 
          <span style={{paddingRight: '35px'}}><span style={{color: '#d6ad5c', fontSize: '18px'}}>&#10162; </span><span>{WeeklyGoals[2].GOAL}</span></span> 
          <span style={{paddingRight: '35px'}}> || </span>
        </span>
        <span>
          {/* Yearly WEALTH Goals */}
          <span style={{paddingRight: '25px'}}><span style={{color: '#d6ad5c', fontWeight: 'bold', fontSize: '18px'}}>Yearly WEALTH Goals</span><span>:</span></span> 
           
          {/* Worship */}
          <span style={{color: '#d6ad5c', paddingRight: '15px', fontSize: '18px'}}>(W)</span>
          <span style={{paddingRight: '35px'}}><span style={{color: '#d6ad5c', fontSize: '18px'}}>&#10162; </span><span>{WorshipGoals[0].GOAL_TITLE}</span></span>
          {((WorshipGoals[1].GOAL_TITLE !== 'Add New Goal') ? 
            <span style={{paddingRight: '35px'}}><span style={{color: '#d6ad5c', fontSize: '18px'}}>&#10162; </span><span>{WorshipGoals[1].GOAL_TITLE}</span></span> 
            : ''
          )}
          {((WorshipGoals[2].GOAL_TITLE !== 'Add New Goal') ? 
            <span style={{paddingRight: '35px'}}><span style={{color: '#d6ad5c', fontSize: '18px'}}>&#10162; </span><span>{WorshipGoals[2].GOAL_TITLE}</span></span> 
            : ''
          )}

          {/* Education */}
          <span style={{color: '#d6ad5c', paddingRight: '15px', fontWeight: 'bold', fontSize: '18px'}}>(E)</span>
          <span style={{paddingRight: '35px'}}><span style={{color: '#d6ad5c', fontSize: '18px'}}>&#10162; </span><span>{EducationGoals[0].GOAL_TITLE}</span></span>
          {((EducationGoals[1].GOAL_TITLE !== 'Add New Goal') ? 
            <span style={{paddingRight: '35px'}}><span style={{color: '#d6ad5c', fontSize: '18px'}}>&#10162; </span><span>{EducationGoals[1].GOAL_TITLE}</span></span> 
            : ''
          )}
          {((EducationGoals[2].GOAL_TITLE !== 'Add New Goal') ? 
            <span style={{paddingRight: '35px'}}><span style={{color: '#d6ad5c', fontSize: '18px'}}>&#10162; </span><span>{EducationGoals[2].GOAL_TITLE}</span></span> 
            : ''
          )}

          {/* Affluence */}
          <span style={{color: '#d6ad5c', paddingRight: '15px', fontWeight: 'bold', fontSize: '18px'}}>(A)</span>
          <span style={{paddingRight: '35px'}}><span style={{color: '#d6ad5c', fontSize: '18px'}}>&#10162; </span><span>{AffluenceGoals[0].GOAL_TITLE}</span></span>
          {((AffluenceGoals[1].GOAL_TITLE !== 'Add New Goal') ? 
            <span style={{paddingRight: '35px'}}><span style={{color: '#d6ad5c', fontSize: '18px'}}>&#10162; </span><span>{AffluenceGoals[1].GOAL_TITLE}</span></span> 
            : ''
          )}
          {((AffluenceGoals[2].GOAL_TITLE !== 'Add New Goal') ? 
            <span style={{paddingRight: '35px'}}><span style={{color: '#d6ad5c', fontSize: '18px'}}>&#10162; </span><span>{AffluenceGoals[2].GOAL_TITLE}</span></span> 
            : ''
          )}

          {/* Lifestyle */}
          <span style={{color: '#d6ad5c', paddingRight: '15px', fontWeight: 'bold', fontSize: '18px'}}>(L)</span>
          <span style={{paddingRight: '35px'}}><span style={{color: '#d6ad5c', fontSize: '18px'}}>&#10162; </span><span>{LifestyleGoals[0].GOAL_TITLE}</span></span>
          {((LifestyleGoals[1].GOAL_TITLE !== 'Add New Goal') ? 
            <span style={{paddingRight: '35px'}}><span style={{color: '#d6ad5c', fontSize: '18px'}}>&#10162; </span><span>{LifestyleGoals[1].GOAL_TITLE}</span></span> 
            : ''
          )}
          {((LifestyleGoals[2].GOAL_TITLE !== 'Add New Goal') ? 
            <span style={{paddingRight: '35px'}}><span style={{color: '#d6ad5c', fontSize: '18px'}}>&#10162; </span><span>{LifestyleGoals[2].GOAL_TITLE}</span></span> 
            : ''
          )}

          {/* Team */}
          <span style={{color: '#d6ad5c', paddingRight: '15px', fontWeight: 'bold', fontSize: '18px'}}>(T)</span>
          <span style={{paddingRight: '35px'}}><span style={{color: '#d6ad5c', fontSize: '18px'}}>&#10162; </span><span>{TeamGoals[0].GOAL_TITLE}</span></span>
          {((TeamGoals[1].GOAL_TITLE !== 'Add New Goal') ? 
            <span style={{paddingRight: '35px'}}><span style={{color: '#d6ad5c', fontSize: '18px'}}>&#10162; </span><span>{TeamGoals[1].GOAL_TITLE}</span></span> 
            : ''
          )}
          {((TeamGoals[2].GOAL_TITLE !== 'Add New Goal') ? 
            <span style={{paddingRight: '35px'}}><span style={{color: '#d6ad5c', fontSize: '18px'}}>&#10162; </span><span>{TeamGoals[2].GOAL_TITLE}</span></span> 
            : ''
          )}

          {/* Health */}
          <span style={{color: '#d6ad5c', paddingRight: '15px', fontWeight: 'bold', fontSize: '18px'}}>(H)</span>
          <span style={{paddingRight: '35px'}}><span style={{color: '#d6ad5c', fontSize: '18px'}}>&#10162; </span><span>{HealthGoals[0].GOAL_TITLE}</span></span>
          {((HealthGoals[1].GOAL_TITLE !== 'Add New Goal') ? 
            <span style={{paddingRight: '35px'}}><span style={{color: '#d6ad5c', fontSize: '18px'}}>&#10162; </span><span>{HealthGoals[1].GOAL_TITLE}</span></span> 
            : ''
          )}
          {((HealthGoals[2].GOAL_TITLE !== 'Add New Goal') ? 
            <span style={{paddingRight: '35px'}}><span style={{color: '#d6ad5c', fontSize: '18px'}}>&#10162; </span><span>{HealthGoals[2].GOAL_TITLE}</span></span> 
            : ''
          )}
        </span>                                                                                                                      
      </Marquee>

      <hr style={{color: 'grey', height: '1', width: '100%', background: 'grey'}}/>
    </RootStyle>
  );
}
