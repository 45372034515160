import { Box, Container, Link, Stack, Typography } from '@mui/material';
// @mui
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
// components
import Page from '../../components/Page';
// routes
import { PATH_AUTH } from '../../routes/paths';
// sections
import { LoginForm } from '../../sections/auth/login';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 500,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <Page title="Login">
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
          <Box sx={{background: '#35404e73', p: '20px', px: '20px', borderRadius: '5%',}}>
            <Box style={{display: 'flex', justifyContent: 'center'}}>
              <img alt={"Logo"} style={{display: 'inline-flex', width: '250px'}} src="../../logo/Logo.png"/><br/><br/>
            </Box>
            <br/>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Sign in to the Wealthy Way Planner
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Enter your details below</Typography>
              </Box>
            </Stack>

            <LoginForm />
              <Typography variant="body1" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link variant="subtitle1" component={RouterLink} to={PATH_AUTH.register}>
                  Sign Up!
                </Link>
              </Typography>
            </Box>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
