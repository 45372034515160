// @mui
import { styled } from '@mui/material/styles';
import { Box, Container, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';


import { useNavigate } from 'react-router-dom';

// components
import Page from '../../components/Page';
// sections
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));


const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 600,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

const buttonStyles = makeStyles ({
    loadingButton: {
      color: 'black',
      width: '140px',  
      display: 'inline-flex', 
    }
  }); 


// ----------------------------------------------------------------------

export default function OWealthInstruction() {
    const navigate = useNavigate();

    const btnClass = buttonStyles(); 
    const previousPage = 'dailyHabits'
    const nextPage = 'worshipGoals'

  return (
    <Page title="Orientation - Wealth">
      <RootStyle>
        <HeaderStyle />
        <Container>
          <ContentStyle>
            <Box sx={{background: '#35404e73', p: '20px', px: '20px', borderRadius: '5%'}}>
            <Typography variant="h4" gutterBottom sx={{textAlign: 'center', color: '#d6ad5c'}}>
            Next we're going to create yearly goals in each aspect of WEALTH.
            </Typography >
            <br/>
            <Typography variant="h6" gutterBottom>
              <ul style={{color: '#d6ad5c', paddingLeft: '40%', listStyleType:'none'}}>
                <li><span style={{color: 'white'}}><span style={{color: '#d6ad5c'}}>W</span>orship</span></li>
                <li><span style={{color: 'white'}}><span style={{color: '#d6ad5c'}}>E</span>ducation</span></li>
                <li><span style={{color: 'white'}}><span style={{color: '#d6ad5c'}}>A</span>ffluence</span></li>
                <li><span style={{color: 'white'}}><span style={{color: '#d6ad5c'}}>L</span>ifestyle</span></li>
                <li><span style={{color: 'white'}}><span style={{color: '#d6ad5c'}}>T</span>eam</span></li>
                <li><span style={{color: 'white'}}><span style={{color: '#d6ad5c'}}>H</span>ealth</span></li>
              </ul>
            <br/>
            <Typography sx={{ color: 'text.secondary', textAlign: 'center' }}>You'll be able to go back and edit these goals later, so don't worry if you're not sure right now!</Typography>
            </Typography>
            <br/>
            <Box sx={{display: 'flex', 
                    flex: 1, 
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',

                  }}>
            <LoadingButton  size="large" type="submit" variant="contained" className={btnClass.loadingButton} onClick={() => navigate(`/orientation/${previousPage}`)} >
            ← Back
            </LoadingButton>

            <LoadingButton size="large" type="submit" variant="contained" className={btnClass.loadingButton} onClick={() => navigate(`/orientation/${nextPage}`)}>
              Next →
            </LoadingButton>
          </Box>
            </Box>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
