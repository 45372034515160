import React from 'react';
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Tutorial',
    icon: <Iconify icon={'icon-park-outline:future-build-three'} {...ICON_SIZE} />,
    path: '/orientation/tutorial',
  },
  {
    title: <span style={{color: 'grey', cursor: 'default', }}>&#9135;&#9135;</span>,
    icon: <Iconify icon={'bx:bx-target-lock'} {...ICON_SIZE} />,
    path: '#',
  },
  {
    title: 'Vision',
    icon: <Iconify icon={'icon-park-outline:future-build-three'} {...ICON_SIZE} />,
    path: '/orientation/vision',
  },
  {
    title: <span style={{color: 'grey', cursor: 'default', }}>&#9135;&#9135;</span>,
    icon: <Iconify icon={'bx:bx-target-lock'} {...ICON_SIZE} />,
    path: '#',
  },
  {
    title: 'Daily',
    icon: <Iconify icon={'bx:bx-target-lock'} {...ICON_SIZE} />,
    path: '/orientation/dailyHabits',
  },
  {
    title: <span style={{color: 'grey', cursor: 'default', }}>&#9135;&#9135;</span>,
    icon: <Iconify icon={'bx:bx-target-lock'} {...ICON_SIZE} />,
    path: '#',
  },
  {
    title: 'Intro',
    icon: <Iconify icon={'bx:bx-target-lock'} {...ICON_SIZE} />,
    path: '/orientation/wealthIntro',
  },
  {
    title: <span style={{color: 'grey', cursor: 'default', }}>&#9135;&#9135;</span>,
    icon: <Iconify icon={'bx:bx-target-lock'} {...ICON_SIZE} />,
    path: '#',
  },
  {
    title: 'W',
    icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
    path: '/orientation/worshipGoals',
  },
  {
    title: 'E',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: '/orientation/educationGoals',
  },
  {
    title: 'A',
    icon: <Iconify icon={'akar-icons:money'} {...ICON_SIZE} />,
    path: '/orientation/affluenceGoals',
  },
  {
    title: 'L',
    icon: <Iconify icon={'ic:baseline-nightlife'} {...ICON_SIZE} />,
    path: '/orientation/lifestyleGoals',
  },
  {
    title: 'T',
    icon: <Iconify icon={'fluent:people-team-16-filled'} {...ICON_SIZE} />,
    path: '/orientation/teamGoals',
  },
  {
    title: 'H',
    icon: <Iconify icon={'carbon:health-cross'} {...ICON_SIZE} />,
    path: '/orientation/healthGoals',
  },
  // {
  //   title: <span style={{color: 'grey', }}>&#9135;&#9135;</span>,
  //   icon: <Iconify icon={'bx:bx-target-lock'} {...ICON_SIZE} />,
  //   path: '#',
  // },
  // {
  //   title: 'Monthly',
  //   icon: <Iconify icon={'bx:bx-target-lock'} {...ICON_SIZE} />,
  //   path: '/orientation/monthlyGoals',
  // },
  // {
  //   title: <span style={{color: 'grey', }}>&#9135;&#9135;</span>,
  //   icon: <Iconify icon={'bx:bx-target-lock'} {...ICON_SIZE} />,
  //   path: '#',
  // },
  // {
  //   title: 'Weekly',
  //   icon: <Iconify icon={'bx:bx-target-lock'} {...ICON_SIZE} />,
  //   path: '/orientation/weeklyGoals',
  // },
  // {
  //   title: <span style={{color: 'grey', }}>&#9135;&#9135;</span>,
  //   icon: <Iconify icon={'bx:bx-target-lock'} {...ICON_SIZE} />,
  //   path: '#',
  // },
  // {
  //   title: 'Daily',
  //   icon: <Iconify icon={'bx:bx-target-lock'} {...ICON_SIZE} />,
  //   path: '/orientation/dailyHabits',
  // },
];

export default menuConfig;
